<template>
  <div>
    <div class="navIndexWrapper inLayoutIgnoreFontUpscale ignoreFontUpscale">
      <div
        class="mainParentContainer tw-border-red tw-h-15 tw-py-[18px] md:tw-h-[80px]"
      >
        <div
          class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between"
        >
          <span class="tw-block lg:tw-hidden">
            <i
              v-if="ACTIVE_STEP > 0"
              class="fas fa-chevron-left tw-cursor-pointer tw-text-base tw-text-gray-500"
              @click="GO_TO_PREVIOUS_STEP"
            />
          </span>
          <NuxtLinkLocale class="navLogo tw-shrink-0" :to="redirectionTo">
            <img :src="getMediaUrl('Logo.svg')" />
            <div>Groover</div>
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
    <VProgressBar :completion="COMPLETION_PERCENTAGE" />
    <slot />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import VProgressBar from '~/components/ui/VProgressBar.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow'

export default defineComponent({
  components: {
    VProgressBar,
  },
  setup() {
    const router = useRouter()
    const { getMediaUrl } = useGetMediaUrl()

    const {
      path: curatorOnboardingFlowPath,
      steps: curatorOnboardingFlowSteps,
    } = storeToRefs(useInfluencerOnboardingFlowStore())
    const redirectionTo = computed(() => {
      if (
        router.currentRoute.value.fullPath.includes(
          curatorOnboardingFlowPath.value,
        ) &&
        curatorOnboardingFlowSteps.value.some((stepName) =>
          router.currentRoute.value.fullPath.includes(stepName),
        )
      )
        return ''
      return '/'
    })

    return { router, getMediaUrl, redirectionTo }
  },
  computed: {
    ...mapState(useInfluencerOnboardingFlowStore, {
      ACTIVE_STEP: 'activeStep',
      COMPLETION_PERCENTAGE: 'COMPLETION_PERCENTAGE',
    }),
  },
  created() {
    this.CHECK_ACTIVE_STEP(this.router.currentRoute.value.path)
  },
  methods: {
    ...mapActions(useInfluencerOnboardingFlowStore, {
      CHECK_ACTIVE_STEP: 'CHECK_ACTIVE_STEP',
      GO_TO_PREVIOUS_STEP: 'GO_TO_PREVIOUS_STEP',
    }),
  },
})
</script>

<style scoped lang="scss">
.navIndexWrapper {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  box-shadow: var(--shadow-regular);
  z-index: 10001;
}

.navLogo {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.navLogo > img {
  @apply tw-h-8 tw-w-6 tw-object-contain tw-object-center;

  @screen md {
    @apply tw-h-4 tw-w-4;
  }
}

.navLogo > div {
  @apply tw-ml-1 tw-font-sans tw-text-xl tw-font-bold tw-text-orange-500;
}
</style>
