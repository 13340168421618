<template>
  <div
    class="tw-h-1 tw-w-full tw-bg-gray-200 tw-transition-all tw-duration-150 tw-ease-in-out md:tw-h-2"
    role="progressbar"
  >
    <div
      class="tw-h-full tw-rounded-r tw-bg-orange-500 tw-transition-all tw-duration-150"
      :style="style"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VProgressBar',
  props: {
    /** .
     * Props for VProgressBar
     *
     * @prop {Number} completion - percentage of completion from 0 to 100.
     */
    completion: {
      type: Number,
      required: true,
    },
  },
  computed: {
    style(): string {
      return `width: ${this.completion}%`
    },
  },
})
</script>
